import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "../assets/images/Logo-inceptive-white.png";
import { useTheme } from "@emotion/react";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  apeCoinLogo,
  // farawayLogo,
  madeByApeLogo,
  madeByUnityLogo,
} from "../staticData/images";

const MobileFooter = ({ ddu }) => {
  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();
  const redirectToHome = () => {
    navigate("/");
  };
  const navigateToTerms = (check) => {
    // setPricyPolicy(true);
    // setCheckBox(check);
    navigate("/terms");
  };
  const navigateToPrivacy = () => {
    // setPricyPolicy(false);
    navigate("/privacy");
  };
  return (
    <Box
      sx={{
        background: "#000",
        bottom: 0,
        position: "fixed",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 0,
          margin: 0,
        }}
      >
        <Box onClick={redirectToHome}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 1,
            }}
          >
            <img
              src={Logo}
              height="36px"
              alt="Logo"
              sx={{
                padding: "8px",
                boxShadow: "5px 5px 5px rgba(149, 149, 149, 0.44)",
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ padding: "4px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://unity.com/", "_blank");
              }}
            >
              <img src={madeByUnityLogo} height="15px" alt="Made by Unity" />
            </Box>
            <Box
              sx={{ padding: "4px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://apechain.com/", "_blank");
              }}
            >
              <img src={madeByApeLogo} height="15px" alt="Made by Ape" />
            </Box>
            <Box
              sx={{ padding: "4px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://apecoin.com/", "_blank");
              }}
            >
              <img src={apeCoinLogo} height="15px" alt="Ape Coin" />
            </Box>
            <Box
              sx={{ padding: "4px", cursor: "pointer" }}
              onClick={() => {
                window.open("https://create.faraway.com/", "_blank");
              }}
            >
              <img
                src="https://storage.googleapis.com/3d-container/Powered%20By%20Ape%20Coin%20White.png"
                width="60px"
                alt="Ape Coin"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider
        sx={{
          border: "1px solid #C4C4C4",
          opacity: 0.3,
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px", // Adjust the padding as needed
          background: "#000",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            gap: "8px", // Increased gap for better spacing
            color: "#fff",
            fontFamily: "Inter",
            fontSize: "11px", // Increased font size
            fontWeight: 500, // Increased font weight for a bolder look
            letterSpacing: "0.02em", // Slightly increased letter spacing
            textAlign: "left",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              textDecoration: "none",
              fontFamily: "var(--font-i_r)",
              color: "rgba(255, 255, 255, 0.4)", // Set the color to white
            }}
            onClick={() => {
              navigate("/faq");
            }}
          >
            FAQ
          </span>
          <span
            style={{
              color: "rgba(255, 255, 25, 0.4)",
            }}
          >
            |
          </span>
          <span
            onClick={() => navigateToTerms()}
            style={{
              textDecoration: "none",
              fontFamily: "var(--font-i_r)",
              color: "rgba(255, 255, 255, 0.4)", // Set the color to white
            }}
          >
            Terms
          </span>
          <span
            style={{
              color: "rgba(255, 255, 25, 0.4)",
            }}
          >
            |
          </span>
          <span
            onClick={() => navigateToPrivacy()}
            style={{
              textDecoration: "none",
              color: "rgba(255, 255, 255, 0.4)", // Set the color to white
              fontFamily: "var(--font-i_r)",
            }}
          >
            Privacy
          </span>
        </Typography>
        <Typography
          sx={{
            color: "rgba(255, 255, 255, 0.4)",
            fontFamily: "Inter",
            fontSize: "9px",
            fontWeight: 400,
            lineHeight: "14px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          © {new Date().getFullYear()} Inceptive Studio All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default MobileFooter;
