import React, { useEffect, useState } from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import { MdMailOutline } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord, FaTelegramPlane } from "react-icons/fa";
import { TiPencil } from "react-icons/ti";
import { PiShareFill } from "react-icons/pi";

import { fetchProfile, updateProfile } from "../../services/clientService";
import useAccountInfo from "../../hooks/useAccountInfo";
import EditProfileModal from "../../components/profile/EditProfileModal";
import { /*ActiveFont,*/ InactiveFont } from "../../components/ui/Texts";
import { BorderedInnerContainer } from "../../components/ui/Layouts";
import PageWrapper from "../../components/common/PageWrapper";

const expoectedSocials = [
  {
    logo: (props) => <FaXTwitter {...props} />,
    name: "Twitter",
    id: "xUsername",
    value: "",
  },
  {
    logo: (props) => <FaDiscord {...props} />,
    name: "Discord",
    id: "discord",
    value: "",
  },
  {
    logo: (props) => <MdMailOutline {...props} />,
    name: "Enter E-mail",
    id: "email",
    value: "",
  },
  {
    logo: (props) => <FaTelegramPlane {...props} />,
    name: "Telegram",
    id: "telegram",
    value: "",
  },
];
const Profile = () => {
  const { shortAddress /*, chainName*/ } = useAccountInfo();

  const [profileData, setProfileData] = useState({});
  const [socials, setSocials] = useState(expoectedSocials);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { palette, ...theme } = useTheme();
  const isMobie = useMediaQuery(theme.breakpoints.down("sm"));

  const onEdit = () => {
    setShowEditProfile(true);
  };

  const onCloseEdit = () => {
    setShowEditProfile(false);
  };

  const onUpdateProfile = async (data) => {
    try {
      setIsLoading(true);
      await updateProfile(data);
      onCloseEdit();
    } catch (error) {
      console.log(error);
    } finally {
      fetchData();
      setIsLoading(false);
      setShowEditProfile(false);
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await fetchProfile();
      const updatedSocials = expoectedSocials.map((social) => ({
        ...social,
        value: data[social.id] ?? "",
      }));
      console.log(data);
      setProfileData(data);
      setSocials(updatedSocials);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageWrapper maxWidth={{ xs: "100%", sm: "600px", lg: "40%" }}>
      <BorderedInnerContainer
        style={{
          padding: "12px 12px 22px 12px",
          border: { xs: "", sm: "0.872px solid #FFB800" },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              height: "160px",
              width: "100%",
              borderRadius: "20px",
              overflow: "hidden",
              backgroundImage: "url(/images/profile_banner.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></Box>
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              bottom: 0,
              transform: "translateX(-50%) translateY(50%)",
            }}
          >
            <img
              width={isMobie ? "124px" : "160px"}
              height={isMobie ? "124px" : "160px"}
              style={{
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={
                profileData?.profileImage ??
                "https://ishrostorage.blob.core.windows.net/container-3d/MAYC%20banner.png"
              }
              alt="profile"
            />
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: { xs: "40px", sm: "44px" },
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontFamily: "var(--font-i_r)",
              fontSize: { xs: "28px", sm: "36px" },
              textAlign: "center",
              color: "#FFF",
            }}
          >
            {profileData?.firstName || profileData?.lastName
              ? `${profileData?.firstName} ${profileData?.lastName}`
              : shortAddress}
          </Typography>
          <InactiveFont
            text="Gs on Ape Holder"
            style={{ fontSize: { xs: "22px", sm: "24px" } }}
          />
          <Box
            sx={{
              paddingTop: "24px",
              paddingBottom: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              onClick={onEdit}
              sx={{
                textTransform: "capitalize",
                display: "flex",
                padding: "6px 8px",
                alignItems: "center",
                gap: "7px",
                borderRadius: "10px",
                border: "1px solid #FFB800",
                backgroundColor: "rgba(255, 184, 0, 0.04)",
              }}
            >
              <InactiveFont text="Edit profile" />
              <TiPencil size={24} />
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
                display: "flex",
                padding: "6px 8px",
                alignItems: "center",
                gap: "7px",
                borderRadius: "10px",
                border: "1px solid #FFB800",
                backgroundColor: "rgba(255, 184, 0, 0.04)",
              }}
            >
              <InactiveFont text="Share profile" />
              <PiShareFill size={24} />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "3x 0px",
              gap: "24px",
              alignSelf: "stretch",
              justifyContent: "center",
            }}
          >
            {socials.map((social) => (
              <Box
                key={social.id}
                sx={{
                  padding: "20px",
                  backgroundColor: "#222",
                  borderRadius: "100%",
                  width: { xs: "58px", sm: "70px" },
                  height: { xs: "58px", sm: "70px" },
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {social.logo({
                  size: isMobie ? 24 : 34,
                  color: "#FFB800",
                })}
              </Box>
            ))}
          </Box>
        </Box>
      </BorderedInnerContainer>
      {/* <BorderedInnerContainer style={{ gap: "10px" }}>
        <ActiveFont text="My Collections" />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          {Array.from({ length: 4 }).map((_, i) => (
            <Box
              key={`collection-${i}`}
              sx={{
                width: { xs: "90%", sm: "191px" },
                height: { xs: "320px", sm: "206px" },
                padding: "2px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "8px",
                border: "0.685px solid #FFB800",
                backgroundColor: "rgba(0, 0, 0, 0.30)",
                backdropFilter: "blur(13.693807601928711px)",
              }}
            >
              <img
                width="100%"
                height="85%"
                style={{
                  objectFit: "cover",
                }}
                src={
                  profileData?.profileImage ??
                  "https://ishrostorage.blob.core.windows.net/container-3d/MAYC%20banner.png"
                }
                alt="profile"
              />
              <ActiveFont
                text="#3155"
                style={{ textAlign: "center", fontSize: "17px" }}
              />
            </Box>
          ))}
        </Box>
      </BorderedInnerContainer> */}

      <EditProfileModal
        key={`profile-of-${profileData.id}`}
        isOpen={showEditProfile}
        onClose={onCloseEdit}
        onUpdate={onUpdateProfile}
        initialData={profileData}
        isLoading={isLoading}
      />
    </PageWrapper>
  );
};

export default Profile;
