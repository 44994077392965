import { Box } from "@mui/material";

export const BorderedInnerContainer = ({ children = null, style = {} }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        padding: "18px",
        borderRadius: "22px",
        border: { xs: "", sm: "2px solid #FFB800" },
        backgroundColor: "rgba(0, 0, 0, 0.30)",
        backdropFilter: "blur(20px)",
        ...style,
      }}
    >
      {children}
    </Box>
  );
};
