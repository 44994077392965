import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const EditProfileModal = ({
  isOpen,
  onClose,
  onUpdate,
  initialData,
  isLoading,
}) => {
  const [formData, setFormData] = useState({
    firstName: initialData?.firstName || "",
    lastName: initialData?.lastName || "",
    bio: initialData?.bio || "",
    xUsername: initialData?.xUsername || "",
    discord: initialData?.discord || "",
    email: initialData?.email || "",
    telegram: initialData?.telegram || "",
    profileImage: initialData?.profileImage || null,
  });

  const [errors, setErrors] = useState({});
  const [previewUrl, setPreviewUrl] = useState(initialData?.profileImage || "");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        profileImage: file,
      }));

      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    const newErrors = {};

    // Validate email if provided
    if (formData.email && !validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formDataObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "profileImage" && formData[key] instanceof File) {
        console.log(formData[key]);

        formDataObj.append(key, formData[key], formData[key].name); // Append file
      } else {
        formDataObj.append(key, formData[key]); // Append other fields
      }
    });

    onUpdate(formDataObj);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#18181B",
          backgroundImage: "none",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ color: "#FFB800", fontWeight: "bold" }}>
          Edit Profile
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {/* Profile Image Upload */}
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="profile-image-upload"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="profile-image-upload">
                  <Box sx={{ position: "relative", display: "inline-block" }}>
                    <Box
                      sx={{
                        width: 120,
                        height: 120,
                        borderRadius: "50%",
                        border: "2px dashed #FFB800",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        overflow: "hidden",
                        backgroundColor: "rgba(255, 184, 0, 0.1)",
                        "&:hover": {
                          backgroundColor: "rgba(255, 184, 0, 0.2)",
                        },
                      }}
                    >
                      {previewUrl ? (
                        <img
                          src={previewUrl}
                          alt="Profile preview"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <CloudUploadIcon
                          sx={{ color: "#FFB800", fontSize: 40 }}
                        />
                      )}
                    </Box>
                  </Box>
                </label>
                <Typography sx={{ color: "#C4C4C4", mt: 1 }}>
                  Click to upload profile image
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                sx={styles.textField}
                InputLabelProps={styles.inputLabel}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                sx={styles.textField}
                InputLabelProps={styles.inputLabel}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Bio"
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                sx={styles.textField}
                InputLabelProps={styles.inputLabel}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Twitter Username"
                name="xUsername"
                value={formData.xUsername}
                onChange={handleChange}
                sx={styles.textField}
                InputLabelProps={styles.inputLabel}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Discord"
                name="discord"
                value={formData.discord}
                onChange={handleChange}
                sx={styles.textField}
                InputLabelProps={styles.inputLabel}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                sx={styles.textField}
                InputLabelProps={styles.inputLabel}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Telegram"
                name="telegram"
                value={formData.telegram}
                onChange={handleChange}
                sx={styles.textField}
                InputLabelProps={styles.inputLabel}
              />
            </Grid>
          </Grid>

          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end", mt: 4 }}
          >
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{
                borderColor: "#FFB800",
                color: "#C4C4C4",
                "&:hover": {
                  borderColor: "#FFB800",
                  backgroundColor: "rgba(255, 184, 0, 0.1)",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading}
              sx={{
                backgroundColor: "#FFB800",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#FFB800",
                  opacity: 0.9,
                },
              }}
            >
              {isLoading ? "Updating..." : "Save Changes"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.23)",
      },
      "&:hover fieldset": {
        borderColor: "#FFB800",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFB800",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: "#fff",
    },
    "& .MuiInputBase-input": {
      color: "#fff",
    },
  },
  inputLabel: {
    sx: {
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-focused": {
        color: "#FFB800",
      },
    },
  },
};

export default EditProfileModal;
