import {
  RainbowKitProvider,
  connectorsForWallets,
  midnightTheme,
} from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultWagmiConfig } from "@web3modal/wagmi";
import { WagmiProvider } from "wagmi";
import { arbitrum, mainnet } from "wagmi/chains";
import {
  metaMaskWallet,
  walletConnectWallet,
  coinbaseWallet,
  ledgerWallet,
  rainbowWallet,
  rabbyWallet,
  magicEdenWallet,
  frameWallet,
  trustWallet,
} from "@rainbow-me/rainbowkit/wallets";

const queryClient = new QueryClient();

// Define ApeChain mainnet
const apechain = {
  id: 33139,
  name: "ApeChain",
  iconBackground: "#000",
  iconUrl: "https://storage.googleapis.com/3d-container/apchain.png",
  nativeCurrency: {
    name: "APE",
    symbol: "APE",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://apechain.calderachain.xyz/http"],
    },
    public: {
      http: ["https://apechain.calderachain.xyz/http"],
    },
  },
  blockExplorers: {
    default: {
      name: "ApeChain Explorer",
      url: "https://apechain.calderaexplorer.xyz",
    },
  },
};

// Define Curtis testnet
const apechain_curtis = {
  id: 33111,
  name: "Curtis",
  iconBackground: "#000",
  iconUrl: "https://storage.googleapis.com/3d-container/apchain.png",
  nativeCurrency: {
    name: "APE",
    symbol: "APE",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://curtis.rpc.caldera.xyz/http"],
    },
    public: {
      http: ["https://curtis.rpc.caldera.xyz/http"],
    },
  },
  blockExplorers: {
    default: {
      name: "Curtis Explorer",
      url: "https://curtis.explorer.caldera.xyz/",
    },
  },
};

// Project configuration
const metadata = {
  name: "Dashbo",
  description: "Dashbo UGC",
  url: "https://dashbo.xyz",
  icons: [""],
};

// Combine all chains
const chains = [
  mainnet,
  arbitrum,
  apechain,
  ...(process.env.NEXT_PUBLIC_ENABLE_TESTNETS === "true"
    ? [apechain_curtis]
    : []),
];

// Configure wallets
const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet, walletConnectWallet, coinbaseWallet],
    },
    {
      groupName: "More",
      wallets: [
        rabbyWallet,
        rainbowWallet,
        magicEdenWallet,
        ledgerWallet,
        frameWallet,
        trustWallet,
      ],
    },
  ],
  {
    projectId: "b3d4f5e6c2a1d8f2f40dad06a8414980",
    appName: metadata.name,
  }
);

// Configure wagmi
export const config = defaultWagmiConfig({
  chains,
  projectId: "b3d4f5e6c2a1d8f2f40dad06a8414980",
  metadata,
  connectors,
  auth: {
    email: false,
    socials: [],
    showWallets: true,
    walletFeatures: false,
  },
});

export function Web3ModalProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={config}>
        <RainbowKitProvider theme={midnightTheme()}>
          {children}
        </RainbowKitProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}
