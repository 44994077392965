import { Box, useMediaQuery, useTheme } from "@mui/material";
import Header from "../Header";
import BackButton from "../BackButton";
import MobileFooter from "../MobileFooter";
import FooterHome from "../footer/FooterHome";

export const bgImg = {
  backgroundImage:
    "url(https://storage.googleapis.com/3d-container/uniformBg.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
};

const PageWrapper = ({ children, maxWidth = "" }) => {
  const { palette, ...theme } = useTheme();
  const isMobie = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={styles.pageContainer}>
      <Header hamburger />
      {!isMobie && (
        <Box sx={styles.backButtonContainer}>
          <BackButton />
        </Box>
      )}
      <Box sx={styles.contentWrapper}>
        <Box sx={{ ...styles.contentContainer, maxWidth }}>{children}</Box>
      </Box>

      <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        {isMobie ? <MobileFooter /> : <FooterHome />}
      </Box>
    </Box>
  );
};

const styles = {
  pageContainer: {
    ...bgImg,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    scrollbarWidth: "none", // For Firefox
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  backButtonContainer: {
    padding: { xs: 2, sm: 5 },
    paddingBottom: 2,
    zIndex: 1000,
    marginTop: "50px",
  },
  contentWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    width: "100%",
    padding: { xs: 2, sm: 5 },
    paddingTop: 0,
    marginTop: { xs: "100px", sm: "0" },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 2,
    paddingBottom: "70px",
  },
};

export default PageWrapper;
