import { Typography } from "@mui/material";

export const Title = ({ titleText = "", style = {} }) => (
  <Typography
    variant="h2"
    sx={{
      color: "#FFB800",
      fontFamily: "var(--font-f_r)", // "F37-Regular"
      fontSize: { xs: "36px", sm: "44px" },
      fontWeight: "700",
      fontStyle: "normal",
      textTransform: "uppercase",
      ...style,
    }}
  >
    {titleText}
  </Typography>
);

export const PrimarySubTitle = ({ text = "", style = {} }) => (
  <Typography
    sx={{
      color: "#FFB800",
      fontSize: "14px",
      fontWeight: "700",
      textTransform: "capitalize",
      lineHeight: "16px",
    }}
  >
    {text}
  </Typography>
);

export const InactiveFont = ({ text = "", style = {} }) => (
  <Typography
    sx={{
      color: "#C4C4C4",
      fontFamily: "var(--font-i_r)", // ""Inter-Regular""
      fontSize: { xs: "16px", sm: "18px" },
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "24px",
      ...style,
    }}
  >
    {text}
  </Typography>
);

export const ActiveFont = ({ text = "", style = {} }) => (
  <Typography
    sx={{
      color: "#FFF",
      fontFamily: "var(--font-i_r)", // ""Inter-Regular""
      fontSize: { xs: "18px", sm: "20px" },
      fontWeight: "500",
      fontStyle: "normal",
      lineHeight: "26px",
      ...style,
    }}
  >
    {text}
  </Typography>
);
