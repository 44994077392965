import React from "react";
import { Box, Divider, Drawer, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

import CustomButton from "../Button";
import { RxCross2 } from "react-icons/rx";
import { useTheme } from "@emotion/react";
import { FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import useNavLinks from "../../hooks/useNavLinks";
import SideBarMobile from "./SideBarMobile";

const Sidebar = ({ isOpen, onClose }) => {
  const { filteredNavItems, handleLogout } = useNavLinks();

  const { palette, ...theme } = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return onlySmallScreen ? (
    <SideBarMobile
      drawerOpen={isOpen}
      toggleDrawer={onClose}
      filteredNavItems={filteredNavItems}
      handleLogout={handleLogout}
    />
  ) : (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#000",
          padding: "20px",
          maxHeight: "100vh",
          width: "40%",
          [theme.breakpoints.down("sm")]: {
            width: "80%",
            padding: "5px",
          },
        },
      }}
    >
      <Box
        width="inherit"
        justifyItems="end"
        justifyContent="right"
        alignItems="end"
        display="flex"
        alignSelf="self-end"
        marginRight="30px"
        marginTop="30px"
        cursor="pointer"
        sx={{ cursor: "pointer" }}
      >
        <RxCross2 onClick={onClose}
          color={palette.button.default} size={30} />
      </Box>
      <Box
        alignSelf="self-start"
        justifyContent="left"
        display="flex"
        flexDirection="column"
        sx={{
          marginTop: { md: "5", xl: "25" },
          marginLeft: "3vw",
          [theme.breakpoints.down("sm")]: {
            marginLeft: "auto",
            marginRight: "30px",
            alignItems: "end",
            marginTop: "40px",
          },
        }}
      >
        {filteredNavItems.map((item, i) => {
          return item.link !== "" ? (
            <Link key={i} to={`${item.link}`}>
              <Typography
                key={i}
                sx={{
                  "&:hover": {
                    color: palette?.button.default,
                    marginLeft: "10px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBlock: "20px",
                    fontSize: "22px",
                  },

                  fontWeight: "700",
                  textAlignLast: "start",
                  cursor: "pointer",
                  marginBlock: "0.8vw",
                  transition: "0.3s",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "1vw",
                  textTransform: "capitalize",
                  textAlign: "center",
                  color: "#fff",
                  padding: { md: 1 },
                  margin: { md: 1 },
                }}
              >
                {item.name}
              </Typography>
            </Link>
          ) : (
            <Typography
              key={i}
              sx={{
                "&:hover": {
                  color: palette?.button.default,
                  marginLeft: "10px",
                  textDecoration: "underline",
                },
                [theme.breakpoints.down("sm")]: {
                  marginBlock: "20px",
                  fontSize: "22px",
                },

                fontWeight: "700",
                textAlignLast: "start",
                cursor: "pointer",
                marginBlock: "0.8vw",
                transition: "0.3s",
                fontFamily: "var(--font-f_r)",
                fontSize: "1vw",
                textTransform: "capitalize",
                textAlign: "center",
                color: "#fff",
                padding: { md: 1 },
                margin: { md: 1 },
              }}
              onClick={handleLogout}
            >
              {item.name}
            </Typography>
          );
        })}
        <CustomButton
          sx={{
            marginInline: "20px",
            display: "none",
            background:
              "linear-gradient(95deg, #B19513 -2.12%, #BF5C00 116.91%)",
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              marginInline: "0px",
            },
          }}
          title="Login"
          logo={null}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "3vw",
          border: "1px solid #C4C4C4",
          [theme.breakpoints.down("sm")]: {
            display: "none",
            marginTop: "10px",
          },
        }}
      />
      <Box sx={{ marginLeft: "3vw" }}>
        <Typography
          sx={{
            fontFamily: "var(--font-i_r)",
            color: palette.font.default,
            fontSize: "1vw",
            fontWeight: "400",
            marginBlock: "10px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          Follow Us
        </Typography>
        <Box
          display="flex"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            verticalAlign: "center",
            alignItems: "flex-start",
            width: "22vw",
            [theme.breakpoints.down("sm")]: {
              width: 110,
            },
          }}
          justifyContent="space-between"
        >
          <AiFillYoutube
            color={palette.font.default}
            style={{ marginRight: "20px" }}
            size={25}
            sx={{ cursor: "pointer" }}

          />
          <FaTiktok
            color={palette.font.default}
            style={{ marginRight: "20px" }}
            size={25}
            sx={{ cursor: "pointer" }}

          />
          <AiOutlineInstagram
            color={palette.font.default}
            style={{ marginRight: "20px" }}
            size={25}
            sx={{ cursor: "pointer" }}

          />
          <BsDiscord
            color={palette.font.default}
            style={{ marginRight: "20px" }}
            size={25}
            sx={{ cursor: "pointer" }}

          />
          <FaXTwitter
            color={palette.font.default}
            style={{ marginRight: "20px" }}
            size={25}
            sx={{ cursor: "pointer" }}
          />

        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
