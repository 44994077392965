import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { ActiveFont, InactiveFont, Title } from "../../components/ui/Texts";
import ImagePageContainer from "../../components/common/ImagePageContainer";

const Privacy = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isChecked, setIsChecked] = useState(false);
  // const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onChangeRecaptcha = (value) => {
    // setRecaptchaValue(value);
  };

  return (
    <ImagePageContainer backgroundImage="https://storage.googleapis.com/3d-container/website-assets/dashbo_terms.png">
      <Title titleText="DashBO privacy and policies" />
      {/* Terms Sections */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        {sections.map((terms) => (
          <Box key={terms.title}>
            <ActiveFont text={terms.title} />
            <InactiveFont
              text={terms.content}
              style={{
                whiteSpace: "pre-line", // Enables line breaks from \n
              }}
            />
          </Box>
        ))}
      </Box>

      {/* Agreement Checkbox */}
      <Box sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              sx={{
                color: "#C4C4C4",
                "&.Mui-checked": {},
              }}
            />
          }
          label={
            <Typography sx={{ color: "#C4C4C4", fontSize: "0.9rem" }}>
              By using DashBO, you acknowledge that you have read, understood,
              and agree to these Terms and Conditions.
            </Typography>
          }
        />
      </Box>

      {/* ReCAPTCHA and Buttons */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "center" : "flex-end",
          gap: 1,
          mt: 1,
        }}
      >
        <ReCAPTCHA
          sitekey="6LcD0AwqAAAAAEvOJEURkpV-LcPRTuq39AabWZhS"
          onChange={onChangeRecaptcha}
          theme="dark"
        />

        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            sx={{
              color: "#000000",
              "&:hover": {
                bgcolor: "#FFB800",
              },
              fontWeight: "bold",
            }}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#ffffff",
              borderColor: "#FFB800",
              "&:hover": {
                bgcolor: "#FFB800",
                color: "#000000",
              },
              fontWeight: "bold",
            }}
          >
            Decline
          </Button>
        </Box>
      </Box>
    </ImagePageContainer>
  );
};

// Terms sections data
const sections = [
  {
    title: "1. Introduction",
    content:
      "Welcome to DashBO! These Terms and Conditions govern your use of our platform, services, and products. By accessing or using DashBO, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, please do not use our platform.",
  },
  {
    title: "2. Eligibility",
    content:
      "To use DashBO, you must be at least 18 years old or the age of majority in your jurisdiction. By using DashBO, you represent and warrant that you meet these eligibility requirements.",
  },
  {
    title: "3. Account Registration",
    content:
      "- You must create an account to access certain features of DashBO.\n- You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and complete.\n- You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
  },
  {
    title: "4. User-Generated Content (UGC)",
    content:
      "- DashBO is a UGC platform that allows users to create, upload, and share content, including 3D animations, poses, face filters, AR experiences, and avatars.\n- By submitting content to DashBO, you grant us a non-exclusive, worldwide, royalty-free license to use, display, reproduce, and distribute your content on our platform and through other media.\n- You retain all ownership rights to your content.",
  },
  {
    title: "5. Community Guidelines",
    content:
      "- You agree to use DashBO in a manner that is respectful and lawful.\n- You must not create, upload, or share content that is offensive, illegal, or infringes on the rights of others.\n- We reserve the right to remove any content that violates these guidelines or our policies.",
  },
  {
    title: "6. Intellectual Property",
    content:
      "- All intellectual property rights in the DashBO platform, including software, designs, and trademarks, are owned by us or our licensors.\n- You may not use, reproduce, or distribute any content from DashBO without our prior written consent.",
  },
  {
    title: "7. ApeChain and ApeCoin Ecosystem",
    content:
      "- DashBO is built on ApeChain and integrates with the ApeCoin ecosystem.\n- Transactions on DashBO may involve cryptocurrency. You acknowledge and accept the risks associated with cryptocurrency transactions, including volatility and security risks.",
  },
  {
    title: "8. Supported Collections",
    content:
      "- DashBO supports content creation for Bored Ape Yacht Club (BAYC), Meebits, Mutant Ape Yacht Club (MAYC), Koda, CloneX, AKCB, Mocaverse, 0n1, Azuki, and other collections.\n- We provide base models and tools for generating 3D models for collections that do not currently offer them.",
  },
  {
    title: "9. Limitation of Liability",
    content:
      "- DashBO is provided 'as is' and 'as available' without any warranties of any kind, either express or implied.\n- We do not warrant that the platform will be uninterrupted, error-free, or free of harmful components.\n- In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of DashBO.",
  },
  {
    title: "10. Termination",
    content:
      "- We reserve the right to terminate or suspend your account at any time, with or without notice, for any reason, including violation of these terms.\n- Upon termination, you must cease all use of DashBO and delete any copies of content obtained through the platform.",
  },
  {
    title: "11. Changes to Terms",
    content:
      "- We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new terms on our website.\n- Your continued use of DashBO after the changes take effect constitutes your acceptance of the new terms.",
  },
  {
    title: "12. Contact Us",
    content:
      "If you have any questions about these Terms and Conditions, please contact us at support@dashbo.com.",
  },
];

export default Privacy;
