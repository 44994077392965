import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Box } from "@mui/material";

const settings = {
  adaptiveHeight: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 2000,
};

const ImageSlider = ({ projects }) => {
  return (
    <Slider className="slick-slidd" {...settings}>
      {projects.map((project) => (
        <Box
          key={project.name || project.image}
          sx={{
            width: "100%",
            height: { xs: "50vh", md: "60vh" },
            position: "relative",
          }}
        >
          <img
            src={project.image}
            alt={project?.name ?? ""}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Box>
      ))}
    </Slider>
  );
};

export default ImageSlider;
