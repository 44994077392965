import React, { useState } from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./sidebar";
import WalletDialog from "./WalletDialog";
import LoginDialog from "./LoginDialog";
import ButtonGroup from "./header/ButtonGroup";
// import { Logo, LogoBC } from "../staticData/images";
import WalletConnectButton from "../wallet/WalletConnectButton";

const Header = ({ hamburger = false, withoutRight = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [mode, setMode] = useState("login");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [walletPopup, setWalletPopup] = useState(false);
  // const [scrolled, setScrolled] = useState(true);
  // const [nfts, setNfts] = useState([]);

  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        "& .MuiPaper-root": {
          boxShadow: "none",
          backgroundColor: "transparent",
          backdropFilter: "blur(16px)", // scrolled ? "blur(16px)" : "none",
        },
        "& .MuiToolbar-root": {
          justifyContent: "center",
        },
      }}
    >
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: { xl: "80px", lg: "60px", md: "35px" },
              width: { xs: "100%", xl: "97%", lg: "99%", md: "100%" },
              "& button": { color: theme.palette.button.default },
              [theme.breakpoints.down("lg")]: {
                mt: 3,
              },
            }}
          >
            <Box
              onClick={() => !withoutRight && navigate("/")}
              sx={{
                display: "flex",
                justifyContent: "center",
                width: { lg: "200px", md: "150px", sm: "100px", xs: "100px" },
                pt: withoutRight ? "20px" : "",
                pr: { lg: "20px", md: "20px", sm: "20px", xs: "20px" },
              }}
            >
              <img
                src={
                  "https://storage.googleapis.com/3d-container/DashBo%20logo.png"
                }
                alt="Logo"
                style={{
                  width: "100%",
                  height: "auto",
                  cursor: "pointer",
                }}
              />
            </Box>
            {!withoutRight && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonGroup />
                <WalletConnectButton />
                {hamburger && (
                  <Box
                    onClick={handleSidebarOpen}
                    sx={{
                      paddingInline: "10px",
                      borderRadius: "4px",
                      backgroundColor: theme.palette.button.default,
                      [theme.breakpoints.down("sm")]: { border: "none" },
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <MenuIcon
                      sx={{
                        fontSize: "46px",
                        [theme.breakpoints.down("sm")]: { fontSize: "38px" },
                        color: "#000",
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={sidebarOpen} onClose={handleSidebarClose} />
      {sidebarOpen && (
        <div
          onClick={handleSidebarClose}
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 999,
          }}
        ></div>
      )}
      <WalletDialog walletPopup={walletPopup} setWalletPopup={setWalletPopup} />
      <LoginDialog
        loginPopup={loginPopup}
        setLoginPopup={setLoginPopup}
        mode={mode}
        setMode={setMode}
      />
    </Box>
  );
};

export default Header;
