import React from "react";
import {
  Typography,
  Drawer,
  List,
  ListItem,
  Box,
  ListItemText,
  Divider,
} from "@mui/material";

import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import SocialMediaIcons from "../SocialMediaIcons";

const DrawerContent = styled("div")({
  position: "sticky",
  top: "0",
  backgroundColor: "black",
  color: "white",
  width: 250,
});

const NavItem = ({ to, name, onClick }) => {
  return (
    <NavLink
      to={to}
      style={{
        textDecoration: "none",
        color: "inherit",
        textTransform: "capitalize",
      }}
      onClick={onClick}
    >
      <ListItem button>
        <ListItemText primary={name} />
      </ListItem>
    </NavLink>
  );
};

const SideBarMobile = ({
  drawerOpen,
  toggleDrawer,
  filteredNavItems,
  handleLogout,
}) => {
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer}
      sx={{ width: 300 }}
    >
      <DrawerContent sx={{ backgroundColor: "black", height: "100%" }}>
        <List
          sx={{ maxHeight: "75vh", overflowY: "auto", textDecoration: "none" }}
        >
          {filteredNavItems.map((item, index) =>
            item.link !== "" ? (
              <NavItem
                key={index}
                to={item.link}
                name={item.name}
                onClick={toggleDrawer}
              />
            ) : (
              <NavItem
                key={index}
                to={""}
                name={item.name}
                onClick={handleLogout}
              />
            )
          )}
        </List>
        <>
          <Divider
            sx={{
              marginTop: "6vw",
              border: "1px solid #C4C4C4",
            }}
          />
          <Box
            sx={{
              marginLeft: "2vw",
            }}
          >
            <Typography
              sx={{
                fontFamily: "var(--font-i_r)",
                fontSize: "18px",
                fontWeight: "700",
                marginBlock: "16px",
              }}
            >
              Follow Us
            </Typography>
            <Box
              display="flex"
              sx={{
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <SocialMediaIcons username="DashBoUGC" />
            </Box>
          </Box>
        </>
      </DrawerContent>
    </Drawer>
  );
};

export default SideBarMobile;
