import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const socialMediaLinks = {
  youtube: "https://www.youtube.com/@",
  tiktok: "https://www.tiktok.com/@",
  twitter: "https://twitter.com/",
  instagram: "https://www.instagram.com/",
  discord: "https://discord.com/users/",
};

const SocialMediaIcons = ({ username, ddu = false }) => {
  const { ...theme } = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("xs")
  );
  return (
    <Box>
      <a
        href={`${socialMediaLinks.youtube}${username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          style={{
            paddingLeft: "8px",
            height: smallScreen ? "28px" : "20px",
            width: "auto",
          }}
          src="https://storage.googleapis.com/3d-container/Youtube.png"
          alt="YouTube"
          loading="lazy"
        />
      </a>
      <a
        href={`${socialMediaLinks.tiktok}${username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          style={{
            paddingLeft: "8px",
            height: smallScreen ? "28px" : "20px",
            width: "auto",
          }}
          src="https://storage.googleapis.com/3d-container/Tiktok.png"
          alt="TikTok"
          loading="lazy"
        />
      </a>
      <a
        href={`${socialMediaLinks.twitter}${username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          style={{
            paddingLeft: "8px",
            height: smallScreen ? "28px" : "20px",

            width: "auto",
          }}
          src="https://storage.googleapis.com/3d-container/Twitter.png"
          alt="Twitter"
          loading="lazy"
        />
      </a>
      <a
        href={`${socialMediaLinks.instagram}${username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          style={{
            paddingLeft: "8px",
            height: smallScreen ? "28px" : "20px",

            width: "auto",
          }}
          src="https://storage.googleapis.com/3d-container/Insta.png"
          alt="Instagram"
          loading="lazy"
        />
      </a>
      {!ddu && (
        <a
          href={`${socialMediaLinks.discord}${username}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              paddingLeft: "8px",
              height: smallScreen ? "28px" : "20px",

              width: "auto",
            }}
            src="https://storage.googleapis.com/3d-container/Discord.png"
            alt="Discord"
            loading="lazy"
          />
        </a>
      )}
    </Box>
  );
};

export default SocialMediaIcons;
