import axios from "../api";
export const fetchCompanies = async (pageSize = 7) => {
  try {
    const response = await axios.get(
      `dashboard/companies?pageSize=${pageSize}&page=1`
    );
    const isValid = response.status === 200;
    return {
      isValid,
      data: isValid ? response?.data?.companies : null,
      error: !isValid ? response.data.message : null,
    };
  } catch (error) {
    return {
      isValid: false,
      data: null,
      error: "Failed to validate client",
    };
  }
};

export const fetchNews = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(`news?page=${page}&limit=${limit}`);
    const isValid = response.status === 200;

    return {
      isValid,
      data: isValid ? response?.data?.items : null,
      pagination: isValid ? response?.data?.pagination : null,
      error: !isValid ? response.data.message : null,
    };
  } catch (error) {
    return {
      isValid: false,
      data: null,
      error: "Failed to validate client",
    };
  }
};

export const fetchProfile = async () => {
  try {
    const response = await axios.get(`auth/profile`);
    const isValid = response.status === 200;

    return {
      isValid,
      data: isValid ? response?.data : null,
      error: !isValid ? response.data.message : null,
    };
  } catch (error) {
    return {
      isValid: false,
      data: null,
      error: "Failed to validate client",
    };
  }
};

export const updateProfile = async (profileData) => {
  try {
    const response = await axios.put(`auth/profile/update`, profileData, {
      headers: {
        "Content-Type": "multipart/form-data", // for file updload
      },
    });
    console.log("Response is ", response);
    const isValid = response.status === 200;

    return {
      isValid,
      data: isValid ? response?.data : null,
      error: !isValid ? response.data.message : null,
    };
  } catch (error) {
    return {
      isValid: false,
      data: null,
      error: "Failed to validate client",
    };
  }
};

export const fetchNewsById = async (id = "") => {
  try {
    const response = await axios.get(`news/${id}`);
    const isValid = response.status === 200;

    return {
      isValid,
      data: response?.data?.data ?? null,
      error: !isValid ? response.data.message : null,
    };
  } catch (error) {
    return {
      isValid: false,
      data: null,
      error: "Failed to validate client",
    };
  }
};

export const fetchCompanyProjects = async (showLoader, hideLoader, slug) => {
  try {
    showLoader();
    const response = await axios.get(`companies/slug/${slug}`);
    const isValid = response.status === 200;
    hideLoader();
    return {
      isValid,
      data: isValid ? response?.data : null,
      error: !isValid ? response.data.message : null,
    };
  } catch (error) {
    hideLoader();
    return {
      isValid: false,
      data: null,
      error: "Failed to validate client",
    };
  }
};
export const getProjectChildrenBySlug = async (slug) => {
  try {
    const response = await axios.get(`collection/children/${slug}`);

    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const clientProjectsDetails = async (project, collection) => {
  try {
    const response = await axios.get(
      `collection/slogan/${project}/${collection}`
    );
    const isValid = response.status === 200;
    return {
      isValid,
      data: isValid ? response?.data : null,
      error: !isValid ? response.data.message : null,
    };
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data?.message || "Server error occurred",
      data: error.response.data,
    };
  }
};
export const clientCollectionData = async (slugan) => {
  try {
    const response = await axios.get(`collection/collectionsData/${slugan}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getTraitsById = async (address, nftId) => {
  const options = {
    method: "GET",
    headers: {
      accept: "*/*",
      "x-api-key": "31505e92-0c8f-5a0d-b902-bff81ed7e665",
    },
  };

  const response = await fetch(
    `https://api.reservoir.tools/collections/${address}/attributes/explore/v5?tokenId=${nftId}`,
    options
  );
  return await response.json();
};

export const getNftById = async (slogan, id) => {
  try {
    const response = await axios.get(`opensea/token/${id}/${slogan}`);
    const isValid = response.status === 200;

    return {
      isValid,
      data: isValid ? response?.data : null,
      error: !isValid ? response.data.message : null,
    };
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data?.message || "Server error occurred",
      data: error.response.data,
    };
  }
};
