import { useAccount, useChains, useChainId } from "wagmi";

export default function useAccountInfo() {
  const { address } = useAccount();
  const chains = useChains();
  const chainId = useChainId();

  const shortAddress = address?.slice(0, 8) ?? "";
  const chainInfo =
    chains && chains.length > 0 && chains.find((chain) => chain.id === chainId);
  let chainName = "";
  if (chainInfo) {
    chainName = chainInfo.name;
  }

  return {
    address,
    shortAddress,
    chainName,
    chains,
    chainId,
    chainInfo,
  };
}
