import { Box, Typography, useTheme } from "@mui/material";

const expectedStatus = ["COMING_SOON", "DEMO", "DEMAND"];

const ClientLabel = ({ status = "", url = "" }) => {
  if (!status) {
    return null;
  }

  const getLabelText = () => {
    switch (status) {
      case "COMING_SOON":
        return "Coming soon";
      case "DEMO":
        return "Demo";
      case "DEMAND":
        return "Demand";

      default:
        return "";
    }
  };

  const labelText = getLabelText();

  return (
    <Box
      sx={{
        backgroundColor: "#FFB800",
        padding: "5px 9px",
        borderRadius: "6px",
        position: "absolute",
        top: "4px",
        right: "4px",
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (labelText?.toLocaleLowerCase() === "demand" && url !== "") {
          window.open(url, "_blank", "noopener,noreferrer"); // open in new tab
        }
      }}
    >
      <Typography
        sx={{
          color: "#000",
          textAlign: "center",
          textTransform: "capitalize",
          fontSize: "10px",
        }}
      >
        {labelText}
      </Typography>
    </Box>
  );
};

const ClientCard = ({
  onClick,
  width,
  imgSrc,
  name,
  minWidth = "",
  status = "",
  twitterUrl = "",
}) => {
  const { palette } = useTheme();

  return (
    <Box
      onClick={onClick}
      sx={{
        margin: "0.5vw",
        cursor: "pointer",
        width,
        minWidth,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backdropFilter: "blur(10px)",
        justifyContent: "center",
        border: "1px solid #95959529",
        alignItems: "center",
        borderRadius: "0.5vw",
        transition:
          "transform 0.3s ease, border 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.02)",
          border: "1px solid #FFB800",
          boxShadow: "0 4px 8px rgba(255, 184, 0, 0.5)",
          ".text-banner-pro": {
            color: "#FFB800",
          },
        },
      }}
    >
      <img
        src={imgSrc}
        style={{
          height: "100%",
          width: "100%",
          margin: "auto",
          borderTopRightRadius: "0.5vw",
          borderTopLeftRadius: "0.5vw",
          overflow: "hidden",
        }}
        alt={name}
        loading="lazy"
      />
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "700",
            textTransform: "capitalize",
            fontFamily: "var(--font-f_r)",
            fontSize: {
              xl: "1.2em",
              lg: "0.8em",
              md: "0.6em",
              xs: "0.8em",
              sm: "0.8em",
            },
            color: palette.grey[200],
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          {name}
        </Typography>
      </Box>
      {expectedStatus.includes(status) && (
        <ClientLabel status={status} url={twitterUrl} />
      )}
    </Box>
  );
};

export default ClientCard;
